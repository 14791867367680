import { Injectable, OnDestroy } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Observable, of, race, Subject } from 'rxjs';
import { catchError, delay, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import getBrowserFingerprint from 'get-browser-fingerprint';
import merge from 'lodash-es/merge';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { APIType } from 'src/app/shared/models/api.model';
import { APIService } from 'src/app/core/services/api.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import { LocalStorageService } from 'ngx-webstorage';

interface ServiceConfig {
  enabled: boolean;
  timeout: number;
}

@Injectable({
  providedIn: 'root',
})
export class ABTestService implements OnDestroy {
  private readonly destroy$ = new Subject<boolean>();
  private readonly localStoragePrefix = 'ab-';

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly loggerService: LoggerService,
    private readonly accountQuery: AccountQuery,
    private readonly cookieService: CookieService,
    private readonly dataLayerService: DataLayerService,
    private readonly apiService: APIService,
    private readonly localStorage: LocalStorageService
  ) {}

  private get predefinedOptions() {
    return {
      ...(this.accountQuery.isAuthenticated && {
        customVariables: {
          userId: this.accountQuery.userData.id,
          userType: this.accountQuery.dataLayerUserType,
        },
      }),
    };
  }

  get uuid(): string | undefined {
    // use the uuid value from the vwo cookie if available, else generate a fingerprint
    const vwoId = this.cookieService.getCookie('_vwo_uuid');
    if (vwoId) {
      return vwoId;
    } else {
      this.loggerService.logEvent(`ABTestService`, '[fingerprint] Provider id not available, generating custom id', SeverityLevel.Error);
      return getBrowserFingerprint()?.toString();
    }
  }

  /**
   * Get the value of an AB test for the current user
   * @param experimentId The ID of the AB test
   * @param defaultValue The default value for the test
   * @param options Additional params to pass as described in the docs: [https://developers.vwo.com/docs/javascript-activate].
   *                userId and userType are automatically provided as customVariables for logged in users.
   * @returns Returns the value of the AB test if it is available for the current user, else returns the defaultValue
   */
  getValue(experimentId: string, defaultValue: string, options: any = {}): Observable<string> {
    const vwoConfig: ServiceConfig = this.appConfig.get('abTesting')?.config;
    if (!vwoConfig || !vwoConfig.enabled) {
      this.loggerService.logEvent(`ABTestService`, '[getValue] Service is disabled, default value will be used', SeverityLevel.Information);
      return of(defaultValue);
    } else if (!experimentId) {
      // avoid querying if a test id was not provided
      return of(defaultValue);
    }

    // check if a value is already stored in the local storage for the test
    const storedValue = this.localStorage.retrieve(`${this.localStoragePrefix}${experimentId}`);
    if (storedValue) {
      return of(storedValue);
    }

    const activateCall = this.apiService
      .post(APIType.BFFGateway, `api/integration/v1/vwo/activate`, {
        campaignKey: experimentId,
        userId: this.uuid,
        options: merge(options, this.predefinedOptions),
      })
      .pipe(
        map(res => {
          if (res?.data?.value) {
            // save test value to local storage to avoid querying again
            this.localStorage.store(`${this.localStoragePrefix}${experimentId}`, res.data.value);
            return res.data.value;
          }
          return defaultValue;
        }),
        catchError((error: Error) => {
          this.loggerService.logEvent(`ABTestService`, `[getValue] Unexpected error occurred: ${error?.message}`, SeverityLevel.Error);
          return of(defaultValue);
        }),
        takeUntil(this.destroy$)
      );

    const timeoutCall = of(defaultValue).pipe(
      delay(vwoConfig.timeout),
      tap(() => {
        this.dataLayerService.createDataLayerEvent({
          event: 'vwo-timeout',
          userId: this.accountQuery.userData?.id,
          timeoutSetting: vwoConfig.timeout,
          fingerprintId: this.uuid,
        });
        this.loggerService.logEvent(
          `ABTestService`,
          `[getValue] Operation exceeded the configured timeout of ${vwoConfig.timeout}ms`,
          SeverityLevel.Error
        );
      })
    );

    return race(activateCall, timeoutCall);
  }

  /**
   * Observe the saved localStorage value of an AB test for the current user
   * @param experimentId The ID of the AB test
   * @returns Returns an observable containing the value of the AB test if it is available, undefined if not
   */
  savedValue(experimentId: string): Observable<string | undefined> {
    return this.localStorage.observe(`${this.localStoragePrefix}${experimentId}`).pipe(
      startWith(this.localStorage.retrieve(`${this.localStoragePrefix}${experimentId}`)),
      map((value: string) => value || undefined),
      takeUntil(this.destroy$)
    );
  }

  /**
   * Track an event for the specified AB test
   * @param experimentId The ID of the AB test
   * @param eventId The ID of the event
   * @param options Additional params to pass as described in the docs: [https://developers.vwo.com/docs/javascript-track].
   *                Event property values should be passed in the 'eventProperties' object.
   *                'userId' and 'userType' are automatically added as customVariables for logged in users.
   */
  track(experimentId: string, eventId: string, options: any = {}): Observable<boolean> {
    const vwoConfig: ServiceConfig = this.appConfig.get('abTesting')?.config;
    if (!experimentId || !eventId || !vwoConfig || !vwoConfig.enabled) {
      return of(false);
    }

    return this.apiService
      .post(APIType.BFFGateway, `api/integration/v1/vwo/track`, {
        campaignKey: experimentId,
        userId: this.uuid,
        goalIdentifier: eventId,
        options: merge(options, this.predefinedOptions),
      })
      .pipe(
        map(res => {
          return res?.data?.tracked ?? false;
        }),
        catchError((error: Error) => {
          this.loggerService.logEvent(`ABTestService`, `[track] Unexpected error occurred: ${error?.message}`, SeverityLevel.Error);
          return of(false);
        }),
        takeUntil(this.destroy$)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
